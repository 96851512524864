@import "./styles/base";

@import "./styles/loading-container";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html {
  font-size: 62.5%;
  line-height: 1.15;
}

body {
  margin: 0;
  background-color: #f8f9fb;
  color: #000a12;
  font-family: Roboto,sans-serif;
  font-size: 1.4rem;
  font-feature-settings: "kern","liga";
}

#loading-container svg {
  filter: hue-rotate(300deg)
          saturate(2)
          brightness(1.1)
          contrast(1.3);
}
